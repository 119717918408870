<template>
  <div>
    <div class="mt-1 mb-1 d-flex justify-content-end">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>
    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">
      <!-- table revisi -->
      <b-container>
        <b-row>
          <b-col>
            <b-card align="left">
              <!-- Table -->
              <table border="1" align="right">
                <tr>
                  <td width="75px">Revisi</td>
                  <td width="125px"> 03</td>
                </tr>
                <tr>
                  <td width="75px">Tanggal</td>
                  <td width="125px"> 01/03/2015</td>
                </tr>
                <tr>
                  <td width="75px">No.</td>
                  <td width="125px"> FORM-PRC-04</td>
                </tr>
              </table>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <!-- Title -->
      <b-card-body class="text-center">
        <b-card-title>{{ $t('apps.transactions.purchase.paymentVoucher.singular.paymentVendor') }}</b-card-title>
      </b-card-body>
      <b-container>
        <b-row>
          <b-col>
            <b-card align="left">
              <!-- Table -->
              <table border="1" align="left">
                <tr>
                  <td width="35px" class="text-center">Due Date</td>
                  <td width="100px"> {{form.dueDate}} </td>
                </tr>
              </table>
            </b-card>
          </b-col>
          <b-col>
            <b-card align="left">
              <!-- Table -->
              <table border="1" align="right">
                <tr>
                  <td width="50px" class="text-center">Slip No.</td>
                  <td width="125px">&nbsp; {{form.slipNo}} </td>
                </tr>
              </table>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <b-container>
        <b-row>
          <b-col>
            <b-card align="left">
              <!-- Table -->
              <table border="1" align="left">
                <tr>
                  <td width="600px">Pay To : {{ form.payTo }}</td>
                  <td width="600px">Date : {{ form.date }}</td>
                </tr>
                <tr>
                  <td width="600px">Amount : {{ form.amount }}</td>
                  <td width="600px">Bank Name : {{ form.bankName }}</td>
                </tr>
                <tr>
                  <td width="600px">No PR/PO : {{ form.noPO }}</td>
                  <td width="600px">Account Number : {{ form.accountNumber }}</td>
                </tr>
                <tr>
                  <td width="600px">Dept. : {{ form.dept }} </td>
                  <td width="600px">Account Name : {{ form.accountName }} </td>
                </tr>
                <tr>
                  <td width="600px" colspan="2">For : {{ form.for }} </td>
                </tr>
              </table>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <b-table-lite id="refListTable" ref="refListTable" no-provider-paging no-provider-filtering :fields="tableColumns"
        :items="form.details" responsive primary-key="id" :sort-by.sync="sortBy" show-empty :busy="isBusy"
        empty-text="No matching records found" :sort-desc.sync="isSortDirDesc" class="position-relative">

        <template #table-busy>
          <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
            <div class="mb-1 text">
              <b-spinner class="align-middle" />
            </div>
            <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
          </div>
        </template>

      </b-table-lite>

      <b-container>
        <b-row>
          <b-col cols="3">
            <b-card align="center">
              <!-- Table -->
              <table border="1">
                <tr>
                  <td width="225px" class="text-center">Approved By</td>
                </tr>
                <tr>
                  <td height="75px" class="text-center"></td>
                </tr>
              </table>
            </b-card>
          </b-col>
          <b-col cols="3">
            <b-card align="center">
              <!-- Table -->
              <table border="1">
                <tr>
                  <td width="200px" class="text-center" colspan="2">Fin & Acc Dept.</td>
                </tr>
                <tr>
                  <td width="100px" class="text-center">Checked By</td>
                  <td width="100px" class="text-center">Manager</td>
                </tr>
                <tr>
                  <td height="52px" class="text-center"></td>
                  <td height="52px" class="text-center"></td>
                </tr>
              </table>
            </b-card>
          </b-col>
          <b-col cols="3">
            <b-card align="center">
              <!-- Table -->
              <table border="1">
                <tr>
                  <td width="250px" class="text-center" colspan="2">Issuing Dept.</td>
                </tr>
                <tr>
                  <td width="125px" class="text-center">Person In Charge</td>
                  <td width="125px" class="text-center">Manager</td>
                </tr>
                <tr>
                  <td height="52px" class="text-center"></td>
                  <td height="52px" class="text-center"></td>
                </tr>
              </table>
            </b-card>
          </b-col>
          <b-col cols="3">
            <b-card align="center">
              <!-- Table -->
              <table border="1">
                <tr>
                  <td width="225px" class="text-center">Recevied By</td>
                </tr>
                <tr>
                  <td height="75px" class="text-center"></td>
                </tr>
              </table>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

    </b-card>
      <div class="mt-2 d-flex flex-sm-column flex-md-row justify-content-between">
        <b-button
              class="mb-sm-1 mb-md-0"
              :variant="'outline-secondary'"
              :to="{ name: 'apps-transactions-form-payment-vendor-list' }"
            >
              <feather-icon
                icon="ArrowLeftIcon"
              />
              {{ $t('globalActions.backToList') }}
            </b-button>
      </div>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BButton,
  BTableLite,
  BSpinner,
  BRow,
  BCol,
  BContainer
} from 'bootstrap-vue'

import useListTable from '@/comp-functions/useListTable'
import { ref, onMounted } from '@vue/composition-api'
import useFormResource from '@/comp-functions/useFormResource'
import { formatDateMonthName, formatCurrency } from '@/utils/formatter'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BButton,
    BTableLite,
    BSpinner,
    BRow,
    BCol,
    BContainer
  },
  computed: {
    companyName() {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup() {
    // export format
    const formats = ['xlsx', 'csv', 'txt']

    // Table Handlers
    const tableColumns = [
      { key: 'no', label: 'No' },
      { key: 'description', label: 'Description' },
      { key: 'date', label: 'Date Of Invoice' },
      { key: 'invoice_no', label: 'Invoice Number' },
      { key: 'bill_amount', label: 'Amount' },
    ]

    const {
      paramsId
    } = useFormResource({ url: 'transaction/purchase/payment-voucher/report/', localeContextPath: 'apps.transactions.purchase.payment.singular.payment', redirectPathName: 'apps-transactions-purchase-payment-voucher' })

    const form = ref({
      payTo: '',
      amount: 0,
      noPO: '',
      dept: '',
      for: '',
      date: '',
      slipNo: '',
      bankName: '',
      accountNumber: '',
      dueDate: '',
      accountName: '',
      details: []
    })

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const getReport = async () => {
      const url = `transaction/purchase/payment-voucher/report/${paramsId}`
      await fetchLists(url)
      const data = itemLists.value
      form.value.payTo          = data.contact.brand
      form.value.slipNo         = data.code 
      form.value.noPO           = data.no_invoices
      form.value.dept           = data.workunit
      form.value.for            = data.for
      form.value.dueDate        = formatDateMonthName(data.due_date_inv.replace(/(\d+[/])(\d+[/])/, '$2$1'))
      form.value.bankName       = data.contact.bank_information[0].bankName
      form.value.accountNumber  = data.contact.bank_information[0].bankAccountNumber
      form.value.accountName    = data.contact.bank_information[0].bankAccountName
      form.value.date           = formatDateMonthName(data.date.replace(/(\d+[/])(\d+[/])/, '$2$1'))
      form.value.amount         = formatCurrency(data.details.reduce((accumulator, { bill_amount }) => accumulator + bill_amount, 0))
      //handle data detail
      data.details.map((field, index) => {
        form.value.details.push({
          no: index + 1,
          description: data.description,
          date: formatDateMonthName(data.date_inv.replace(/(\d+[/])(\d+[/])/, '$2$1')),
          invoice_no: field.invoice_no,
          bill_amount: formatCurrency(field.bill_amount)
        })
      })
    }
    onMounted(async () => {
      await getReport()
    })

    const three = ' ->03'

    return {
      formats,
      three,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      paramsId,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      form,
      formatCurrency,
      formatDateMonthName
    }
  },
  methods: {
    print() {
      window.print()
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.dropdown-menu .dropdown-item {
  width: 100%;
}

@media print {
  #app {
    * {
      visibility: hidden;
      margin: 0 !important;
    }

    #pdfDom,
    #pdfDom * {
      visibility: visible;
    }

    #pdfDom {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      margin-top: 100px;
    }
  }
}

@page {
  size: auto;
}
</style>
